import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';

let stripePromise;
const getStripe = () => {
	if (!stripePromise) {
		stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
		//stripePromise = loadStripe('pk_test_51JzdJICsIzuNN7b2IfB2gjuuFXRNgdWmDwZzrvfHmZ9RLMnhkAzTXcgBxET8IUIZpOGoQpeK9XlVY87o3tpntF4200WIiC9y26');
	}
	return stripePromise;
};

const Checkout = (props) => {
	const stripeState = props.stripeData;
	const configArray = Object.values(props.item);
	let configValues = [];
	let index;

	configArray.forEach((entry) => {
		configValues.push(entry['configId']);
	});

	configValues = JSON.stringify(configValues);
	stripeState.some((entry, i) => {
		const id = JSON.stringify(entry.asdfId);
		if (id === configValues) {
			index = i;
			return true;
		}
	});

	const [stripeError, setStripeError] = useState(null);
	const item = {
		price: stripeState[index].stripeId,
		quantity: 1
	};

	const checkoutOptions = {
		lineItems: [item],
		mode: 'payment',
		successUrl: `${window.location.origin}/success`,
		cancelUrl: `${window.location.origin}`
	};

	const redirectToCheckout = async () => {
		const stripe = await getStripe();
		const { error } = await stripe.redirectToCheckout(checkoutOptions);

		if (error) setStripeError(error.message);
	};

	if (stripeError) alert(stripeError);

	return redirectToCheckout();
};

export default Checkout;
