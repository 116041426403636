import { ThemeProvider } from '@mui/material/styles';
import { myLynxTheme } from './provider/Theme';
import React, { Component } from 'react';
import LoadingScreen from './LoadingScreen';
import ProductSelection from './ProductSelection';
import ProductPreview from './ProductPreview';
import ProductSummary from './ProductSummary';
import logo from '../../img/static/logo.svg';
import BackButton from './BackButton';

class NewProductConfigurator extends Component {
	constructor(props) {
		super(props);
		const currentSelection = {};
		this.props.categories.forEach((val) => {
			val.items.forEach((item) => {
				if (!item.layer && val.layer) item.layer = val.layer;
				if (!item.default) return;
				currentSelection[val.name] = item;
			});
		});

		this.state = {
			currentSelection: currentSelection,
			currentPersonalization: '',
			loaded: false
		};

		this.updateSelection = this.updateSelection.bind(this);
		this.handleBuyClick = this.handleBuyClick.bind(this);
		this.summaryDialogOnEdit = this.summaryDialogOnEdit.bind(this);
		this.updatePersonalization = this.updatePersonalization.bind(this);
		this.handleLoadingScreen = this.handleLoadingScreen.bind(this);

		this.confirmBuyDialog = React.createRef();
	}

	componentDidMount() {
		if (this.props.preloadImages) {
			this.props.categories.forEach((cat) => {
				cat.items.forEach((item) => {
					const img = new Image();
					img.src = item.image;
					img.addEventListener('load', this.handleImageLoaded);
					this.imagesLeftToLoad++;
					if (item.thumbnail) {
						const thumb = new Image();
						thumb.src = item.thumbnail;
						thumb.addEventListener('load', this.handleImageLoaded);
						this.imagesLeftToLoad++;
					}
				});
			});
		}
	}

	summaryDialogOnEdit(category) {
		this.openSelectionCategory(category);
	}

	updateSelection(category, item) {
		const state = this.state;
		state.currentSelection[category] = item;
		this.setState(state);
		if (this.props.onItemChange) this.props.onItemChange(category, item);
	}

	updatePersonalization(event) {
		const state = this.state;
		state.currentPersonalization = event;
		this.setState(state);
		console.log(event);
	}

	handleBuyClick() {
		console.log('kaufen geklickt');
		if (this.confirmBuyDialog !== null && this.confirmBuyDialog.current !== null) this.confirmBuyDialog.current.open();
	}

	handleLoadingScreen() {
		// if (this.props.showLoadingScreen) this.setState({ loaded: true });
	}

	render() {
		return (
			<div className="MyLynx-Configurator ">
				<ThemeProvider theme={myLynxTheme}>
					<LoadingScreen visible={this.props.showLoadingScreen} />
					<div className="MyLynx__logo">
						<a href={this.props.homeLink}>
							<img src={logo} alt="Thurner Snowlynx" />
						</a>
					</div>
					<div className="MyLynx__back">
						<BackButton backLink={this.props.backLink} />
					</div>
					<ProductPreview currentSelection={this.state.currentSelection} currentPersonalization={this.state.currentPersonalization} />

					<ProductSelection categories={this.props.categories} onChangeSelection={this.updateSelection} name={this.props.name} price={this.calculatePrice()} onBuy={this.handleBuyClick} setOpenCategory={(openCategory) => (this.openSelectionCategory = openCategory)} onPersonalize={this.updatePersonalization} />

					<ProductSummary ref={this.confirmBuyDialog} currentSelection={this.state.currentSelection} currentPersonalization={this.state.currentPersonalization} onConfirm={this.props.onBuy} onAbort={this.props.onAbortBuy} onPrivacyPolicy={this.props.onPrivacyPolicy} onEdit={this.summaryDialogOnEdit} />
				</ThemeProvider>
			</div>
		);
	}

	calculatePrice() {
		let price = 0;
		Object.entries(this.state.currentSelection).forEach((val) => {
			price += val[1].price ?? 0.0;
		});
		return price;
	}
}

export default NewProductConfigurator;
