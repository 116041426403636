import { Close } from '@mui/icons-material';
import { IconButton, ListItem, ListItemAvatar, ListItemText, Drawer, List } from '@mui/material';
import React, { Component } from 'react';
import { Thumbnail } from './Thumbnail';

class ProductCategory extends Component {
	constructor(props) {
		super(props);

		let defaultItem = props.category.items[0];
		props.category.items.forEach((item) => {
			if (!item.default) return;
			defaultItem = item;
		});

		this.state = {
			drawerOpen: false,
			currentItem: defaultItem
		};

		this.toggleSubmenu = this.toggleSubmenu.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.openSubmenu = this.openSubmenu.bind(this);
		this.closeSubemnu = this.closeSubemnu.bind(this);
	}

	componentDidMount() {
		this.props.setSubmenuHandlers(this.openSubmenu, this.closeSubemnu);
	}

	toggleSubmenu() {
		this.setState((prevState) => {
			return { drawerOpen: !prevState.drawerOpen };
		});
	}

	openSubmenu() {
		if (this.state.drawerOpen) return;
		this.toggleSubmenu();
	}

	closeSubemnu() {
		if (!this.state.drawerOpen) return;
		this.toggleSubmenu();
	}

	updateItem(item) {
		this.setState({ currentItem: item });
		this.props.onItemChange(this.props.category.name, item);
		this.closeSubemnu();
	}

	render() {
		const items = this.props.category.items.map((item) => {
			return (
				<ListItem button key={'product-category-item' + item.name} className="list-item" onClick={() => this.updateItem(item)} disableRipple disablePadding>
					<ListItemAvatar>
						<Thumbnail color={item.color} Thumbnail={item.thumbnail} />
					</ListItemAvatar>
					<ListItemText primary={item.name} />
				</ListItem>
			);
		});

		const drawer = (
			<div className="selection__wrapper">
				<div className="selection__header">
					<h1>{this.props.category.name}</h1>
					<IconButton onClick={this.closeSubemnu} className="selection__close" disableRipple>
						<Close />
					</IconButton>
				</div>
				<div className="selection__content--holder">
					<div className="selection__content--outer">
						<div className="selection__content">
							<List className="selection__list" disablePadding>
								{items}
							</List>
							{/* Placeholder component so items get scrollable */}
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<ListItem button key={'product-category-' + this.props.category.name} className={'list-item'} onClick={this.openSubmenu} disableRipple disablePadding>
				<ListItemAvatar>
					<Thumbnail color={this.state.currentItem.color} thumbnail={this.state.currentItem.thumbnail} />
				</ListItemAvatar>
				<ListItemText primary={this.props.category.name} />
				<Drawer
					variant="persistent"
					anchor="right"
					open={this.state.drawerOpen}
					classes={{
						paper: 'test-drawer-xyz'
					}}
					ModalProps={{
						keepMounted: true // Better open perfomance on mobile
					}}
				>
					{drawer}
				</Drawer>
			</ListItem>
		);
	}
}

export default ProductCategory;
