import { createTheme } from '@mui/material/styles';

export const myLynxTheme = createTheme({
	typography: {
		fontFamily: ['Beatrice'].join(',')
	},
	palette: {
		type: 'lynx',
		primary: {
			main: '#002E70'
		},
		secondary: {
			main: '#EBEAE5'
		},
		white: {
			main: '#FFFFFF'
		}
	},
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					color: '#002E70',
					backgroundColor: 'transparent',
					boxShadow: 'none'
				}
			}
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					padding: 0
				}
			}
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					paddingLeft: 0,
					paddingRight: 0
				}
			}
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: '0',
					border: '0'
				}
			}
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					color: '#002E70',
					backgroundColor: '#EBEAE5',
					padding: 0
				}
			}
		}
	}
});
