import { ChevronLeftSharp } from '@mui/icons-material';

const BackButton = (props) => {
	const backLink = props.backLink;

	return (
		<button className="button button-back" onClick={() => window.open(backLink, '_self')}>
			<ChevronLeftSharp /> zurück
		</button>
	);
};

export default BackButton;
