import { Fade, LinearProgress } from '@mui/material';

const LoadingScreen = (props) => {
	return (
		<Fade in={props.visible} unmountOnExit>
			<div className="MyLynx__loading">
				<div className="loading__container">
					<p className="teaser">Snowlynx Konfigurator lädt</p>
					<LinearProgress color="inherit" />
				</div>
			</div>
		</Fade>
	);
};

export default LoadingScreen;
