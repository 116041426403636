import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export function Thumbnail(props) {
	const avatar =
		!props.thumbnail && props.color ? (
			<Box
				className="thumbnail"
				color={props.color}
				style={{
					backgroundColor: props.color,
					height: '56px',
					width: '56px',
					marginRight: 10
					//borderRadius: '50%'
				}}
			/>
		) : (
			<Avatar src={props.thumbnail} variant="square" sx={{ width: 56, height: 56, paddingRight: 10 }} />
		);

	return avatar;
}
