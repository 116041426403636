import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { Component } from 'react';

export class ProductPersonalization extends Component {
	constructor(props) {
		super(props);
		this.updatePersonalization = this.updatePersonalization.bind(this);
	}

	updatePersonalization(event) {
		console.log(event.target.value);
		this.props.updatePersonalize(event.target.value);
	}

	render() {
		return (
			<div className="selection__personalize">
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<strong>Personalisierung</strong>
					</AccordionSummary>
					<AccordionDetails>
						<form onSubmit={this.updatePersonalization}>
							<input type="text" name="personalisierung" placeholder="max. 16 Zeichen" onChange={this.updatePersonalization} maxLength={16} />
						</form>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	}
}

export default ProductPersonalization;
