import React, { Component } from 'react';
import defaultFront from '../../img/lynx/lynxFinishDefault.png';
import defaultBack from '../../img/lynx/lynxFinishDefault.png';

class ProductPreview extends Component {
	render() {
		const selection = this.props.currentSelection;
		const personalization = this.props.currentPersonalization;
		const images = Object.keys(selection)
			.sort((a, b) => (selection[a].layer ?? 0) - (selection[b].layer ?? 0))
			.map((key) => {
				return <img className="preview-image" key={'preview-image-' + key} src={selection[key].image} alt={selection[key].name} />;
			});

		let front;
		let back;

		front = Object.keys(images).length === 0 ? <img className="preview-image" src={defaultFront} alt="hallo" /> : images;

		back = Object.keys(images).length === 0 ? <img className="preview-image" src={defaultBack} alt="hallo" /> : images;

		// console.log(test);
		return (
			<div className="MyLynx__preview">
				<div className="preview__front preview__image">
					<p className="teaser">Vorderseite</p>
					{front}
					<p className="personalization">{personalization}</p>
				</div>
				<div className="preview__back preview__image">
					<p className="teaser">Rückseite</p>
					{back}
				</div>
			</div>
		);
	}
}

export default ProductPreview;
