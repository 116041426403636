const Cancel = () => {
	return (
		<div>
			<h1>Cancel</h1>
			<h2>Your payment was canceled.</h2>
		</div>
	);
};

export default Cancel;
