import { Edit } from '@mui/icons-material';
import { Button, ListItem } from '@mui/material';
import React, { Component } from 'react';
import { Thumbnail } from '../Thumbnail';
import NumberFormat from 'react-number-format';
export class SummaryListItem extends Component {
	constructor(props) {
		super(props);
		this.handleOnEdit = this.handleOnEdit.bind(this);
	}

	handleOnEdit() {
		if (!this.props.onEdit) return;
		this.props.onEdit(this.props.category);
	}

	render() {
		const editButton = this.props.displayEdit ? (
			<Button
				onClick={this.handleOnEdit}
				className="edit-button"
				style={{
					height: '100%',
					borderRadius: '0'
				}}
			>
				<Edit />
			</Button>
		) : null;
		return (
			<ListItem className="summary__list--item" disablePadding>
				<div className="item__content">
					<Thumbnail thumbnail={this.props.item.thumbnail} color={this.props.item.color} />
					<div className="item__title">
						<p className="cat">{this.props.category}</p>
						<div className="item__price">
							<p className="name">{this.props.item.name}</p>
							<p className="price">
								<NumberFormat value={this.props.item.price} displayType="text" thousandSeparator={'.'} decimalScale={2} isNumericString={true} prefix="€ " decimalSeparator="," fixedDecimalScale={true} />
							</p>
						</div>
					</div>
				</div>
				<div className="item__edit">{editButton}</div>
			</ListItem>
		);
	}
}

export default SummaryListItem;
