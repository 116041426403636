import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

export function SummaryDialog(props) {
	const fullScreen = useMediaQuery('(max-width:1199px)');
	return (
		<Dialog {...props} fullWidth={true} fullScreen={fullScreen} maxWidth="lg">
			{props.children}
		</Dialog>
	);
}
