import { List } from '@mui/material';
import React, { Component } from 'react';
import SummaryListItem from './SummaryListItem';

export class SummaryList extends Component {
	render() {
		const { itemConfiguration, onEdit } = this.props;

		return (
			<List disablePadding className="summary__list">
				{Object.entries(itemConfiguration).map((val) => {
					return <SummaryListItem category={val[0]} item={val[1]} key={'summary-list-item-' + val[0]} displayEdit onEdit={onEdit} />;
				})}
			</List>
		);
	}
}

export default SummaryList;
