import '@stripe/stripe-js';
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import NewProductConfigurator from './components/myLynx/NewProductConfigurator';

import Checkout from './components/myLynx/Checkout/Checkout';
import Success from './components/myLynx/Checkout/Success';
import Cancel from './components/myLynx/Checkout/Cancel';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bought: false,
			boughtItem: {},
			defaultItem: [],
			stripeJson: [],
			catJson: [],
			dataLoaded: false,
			Preloader: true
		};

		this.onBuy = this.onBuy.bind(this);
	}

	componentDidMount() {
		fetch(`${window.location.origin}/remoteStripeConfig.json`)
			.then((response) => response.json())
			.then((responseJson) => {
				this.setState({ stripeJson: responseJson.data });
			});

		fetch(`${window.location.origin}/remoteData.json`).then((response) =>
			response.json().then((responseJson) => {
				this.setState({ catJson: responseJson.cats, dataLoaded: true });
				setTimeout(() => {
					this.setState({ Preloader: false });
				}, 2000);
			})
		);
	}

	onBuy(selection) {
		this.setState({
			bought: true,
			boughtItem: selection
		});
	}

	render() {
		let cats = this.state.catJson;

		if (this.state.bought) {
			return (
				<div className="state-is-bought">
					<Checkout item={this.state.boughtItem} stripeData={this.state.stripeJson} />
				</div>
			);
		}

		if (this.state.dataLoaded === false) {
			return null;
		}

		return (
			<div className="App">
				<Router>
					<Routes>
						<Route index element={<NewProductConfigurator name="Dein Snowlynx" homeLink={'https://thurner-skimanufaktur.at/'} backLink={'https://thurner-skimanufaktur.at/'} categories={cats} defItem={this.state.defaultItem} preloadImages onBuy={this.onBuy} showLoadingScreen={this.state.Preloader} theme="lynx" onItemChange={(category, item) => console.log('Callback: onItemChange; Category: ' + category + ', item: ' + JSON.stringify(item))} />} />
						<Route path="success" element={<Success />} />
						<Route path="cancel" element={<Cancel />} />
					</Routes>
				</Router>
			</div>
		);
	}
}

export default App;
