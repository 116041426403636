import React, { Component } from 'react';
import { Drawer, Hidden, IconButton, List } from '@mui/material';
import { ChevronRight, Menu } from '@mui/icons-material';
import ProductCategory from './ProductCategory';
import ProductPersonalization from './ProductPersonalization';
import PriceBuy from './PriceBuy';

class ProductSelection extends Component {
	openProductCategories = {};
	closeProductCategories = {};

	constructor(props) {
		super(props);
		this.state = {
			mobileDrawerOpen: false
		};
		this.openSpecificCategory = this.openSpecificCategory.bind(this);
		this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
		this.handleDrawerClose = this.handleDrawerClose.bind(this);
		this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
	}

	componentDidMount() {
		this.props.setOpenCategory(this.openSpecificCategory);
	}

	handleDrawerToggle() {
		this.setState((prevState) => {
			return { mobileDrawerOpen: !prevState.mobileDrawerOpen };
		});
	}

	handleDrawerClose() {
		this.setState({ mobileDrawerOpen: false });
	}

	handleDrawerOpen() {
		this.setState({ mobileDrawerOpen: true });
	}

	openSpecificCategory(category) {
		if (!(category in this.openProductCategories)) return;
		this.handleDrawerOpen();
		Object.values(this.closeProductCategories).forEach((closeHandlers) => closeHandlers.forEach((handler) => handler()));
		this.openProductCategories[category].forEach((value) => value());
	}

	addProductCategoryHandlers(categoryName, openSubmenu, closeSubmenu) {
		if (!this.openProductCategories[categoryName]) this.openProductCategories[categoryName] = [];
		if (!this.closeProductCategories[categoryName]) this.closeProductCategories[categoryName] = [];
		this.openProductCategories[categoryName].push(openSubmenu);
		this.closeProductCategories[categoryName].push(closeSubmenu);
	}

	render() {
		const categories = this.props.categories.map((val) => (
			<ProductCategory
				setSubmenuHandlers={(openSubmenu, closeSubmenu) => {
					this.addProductCategoryHandlers(val.name, openSubmenu, closeSubmenu);
				}}
				key={val.name}
				category={val}
				drawerWidth={600}
				onItemChange={(category, item) => this.props.onChangeSelection(category, item)}
			/>
		));

		const drawer = (
			<div className="selection__wrapper">
				<div className="selection__header">
					<h1>{this.props.name}</h1>
					<IconButton onClick={this.handleDrawerClose} className="sidebar__close close-sidebar" disableRipple>
						<ChevronRight />
					</IconButton>
				</div>
				<div className="selection__content--holder">
					<div className="selection__content--outer">
						<div className="selection__content">
							<List className="selection__list" disablePadding>
								{categories}
							</List>
							<ProductPersonalization updatePersonalize={this.props.onPersonalize} />
							<PriceBuy price={this.props.price} onBuy={this.props.onBuy} />
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div className="MyLynx__selection">
				<IconButton color="inherit" aria-label="open sidebar" edge="start" onClick={this.handleDrawerToggle} className="sidebar__open open-sidebar">
					<Menu />
				</IconButton>
				<nav className="MyLynx__navigation" aria-label="mailbox folders">
					<Hidden lgUp implementation="css">
						<Drawer
							variant="temporary"
							anchor="right"
							open={this.state.mobileDrawerOpen}
							onClose={this.handleDrawerClose}
							classes={{
								root: 'i-am-root',
								paper: 'MyLynx__sidebar-outer mobile'
							}}
							ModalProps={{
								keepMounted: true
							}}
							className="mobile-sidebar"
						>
							{drawer}
						</Drawer>
					</Hidden>
					<Hidden lgDown implementation="css">
						<Drawer
							classes={{
								paper: 'MyLynx__sidebar-outer not-mobile'
							}}
							variant="permanent"
							open
							anchor="right"
						>
							{drawer}
						</Drawer>
					</Hidden>
				</nav>
			</div>
		);
	}
}

export default ProductSelection;
