import { ChevronRightSharp, Close } from '@mui/icons-material';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { Component } from 'react';
import ProductPreview from './ProductPreview';
import { SummaryDialog } from './Summary/SummaryDialog';
import { SummaryList } from './Summary/SummaryList';
import NumberFormat from 'react-number-format';
export class ProductSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};

		this.handleAbort = this.handleAbort.bind(this);
		this.handleBuy = this.handleBuy.bind(this);
		this.handleOnEdit = this.handleOnEdit.bind(this);
	}

	open() {
		this.setState({ open: true });
	}

	close() {
		this.setState({ open: false });
	}

	handleBuy() {
		this.close();
		if (this.props.onConfirm) this.props.onConfirm(this.props.currentSelection);
	}

	handleAbort() {
		this.close();
		if (this.props.onAbort) this.props.onAbort();
	}

	handleOnEdit(category) {
		this.close();
		this.props.onEdit(category);
	}

	calculatePrice() {
		let price = 0;
		Object.values(this.props.currentSelection).forEach((item) => (price += item.price ?? 0));
		return price;
	}

	render() {
		const BootstrapSummaryTitle = (props) => {
			const { children, onClose, ...other } = props;

			return (
				<DialogTitle sx={{ m: 0, p: '20px', color: '#002E70', backgroundColor: '#EBEAE5', borderBottom: '1px solid #d5d3c8' }} {...other}>
					{children}
					{onClose ? (
						<IconButton
							aria-label="close"
							onClick={onClose}
							sx={{
								position: 'absolute',
								right: '10px',
								top: '16px',
								color: '#002E70'
							}}
							disableRipple
						>
							<Close />
						</IconButton>
					) : null}
				</DialogTitle>
			);
		};

		return (
			<SummaryDialog open={this.state.open} onClose={this.handleAbort} className="MyLynx__summary">
				<BootstrapSummaryTitle onClose={this.handleAbort}>Zusammenfassung</BootstrapSummaryTitle>
				<DialogContent className="summary__content--outer">
					<div className="summary__preview">
						<ProductPreview currentSelection={this.props.currentSelection} currentPersonalization={this.props.currentPersonalization} />
					</div>
					<div className="summary__content">
						<SummaryList itemConfiguration={this.props.currentSelection} onEdit={this.handleOnEdit} />
						<div className="summary__personalized">
							<p>Ihre gewünschte Personalisierung</p>
							<p className="personalized__text">{this.props.currentPersonalization}</p>
						</div>
						<div className="summary__disclaimer">
							<p>Die abgebildete Konfiguration ist ein Symbolbild. Holz ist ein natürlicher Werkstoff und kann in der Realität von dem gezeigten Bild abweichen.</p>
						</div>
						<div className="summary__price">
							<p className="teaser">Gesamtsumme</p>
							<div className="price-box">
								<span className="big">
									{/* &euro; {this.calculatePrice()} */}
									<NumberFormat value={this.calculatePrice()} displayType="text" thousandSeparator={'.'} decimalScale={2} isNumericString={true} prefix="€ " decimalSeparator="," fixedDecimalScale={true} />
								</span>
								<span className="small">inkl. 20% USt.</span>
							</div>
							<button className="button button-buy" onClick={this.handleBuy}>
								Zur Bezahlung <ChevronRightSharp />
							</button>
						</div>
					</div>
				</DialogContent>
			</SummaryDialog>
		);
	}
}

export default ProductSummary;
