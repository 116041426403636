import { ChevronRightSharp } from '@mui/icons-material';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

class PriceBuy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			className: ''
		};

		this.priceElem = React.createRef();
	}

	render() {
		return (
			<div className="selection__checkout--outer">
				<div className="selection__checkout">
					<p className="teaser">Gesamt</p>
					<div className="price-box">
						<span ref={this.priceElem} className="big">
							<NumberFormat value={this.props.price} displayType="text" thousandSeparator={'.'} decimalScale={2} isNumericString={true} prefix="€ " decimalSeparator="," fixedDecimalScale={true} />
						</span>
						<span className="small">inkl. 20% USt.</span>
					</div>
					<button className="button button-buy" onClick={this.props.onBuy}>
						Kaufen <ChevronRightSharp />
					</button>
				</div>
			</div>
		);
	}
}

export default PriceBuy;
